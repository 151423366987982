<template>
  <PEsqueciSenha />
</template>

<script>
import PEsqueciSenha from '@/components/p-esqueci-senha/index.vue';
export default {
  components: {
    PEsqueciSenha,
  },

};
</script>
